export const disability_content = `
<table class="employee-style separater-little">
  <thead>
    <tr>
      <th style="width:20px;"></th>
      <th style="width:50%;">障害者　</th>
      <th style="width:50%;">特別障害者</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border-bottom">①</td>
      <td class="border-bottom">精神上の障害により事理を弁識する能力を欠く常況にある人</td>
      <td class="border-bottom border-left bit-padding-left">左記の全て</td>
    </tr>
    <tr>
      <td class="border-bottom">②</td>
      <td class="border-bottom">精神保健指定医などから知的障害者と判定された人</td>
      <td class="border-bottom border-left bit-padding-left">重度の知的障害者と判定された人</td>
    </tr>
    <tr>
      <td class="border-bottom">③</td>
      <td class="border-bottom">精神障害者保健福祉手帳の交付を受けている人</td>
      <td class="border-bottom border-left bit-padding-left">障害等級が1級の人</td>
    </tr>
    <tr>
      <td class="border-bottom">④</td>
      <td class="border-bottom">身体障害者手帳に身体上の障害がある者として記載がされている人</td>
      <td class="border-bottom border-left bit-padding-left">障害の程度が1級又は2級の人</td>
    </tr>
    <tr>
      <td class="border-bottom">⑤</td>
      <td class="border-bottom">戦傷病者手帳の交付を受けている人</td>
      <td class="border-bottom border-left bit-padding-left">傷害の程度が恩給法別表第1号表の2の特別項症から第3項症までの人</td>
    </tr>
    <tr>
      <td class="border-bottom">⑥</td>
      <td class="border-bottom">原子爆弾被爆者に対する援護に関する法律の規定による厚生労働大臣の認定を受けている人</td>
      <td class="border-bottom border-left bit-padding-left">左記の全て</td>
    </tr>
    <tr>
      <td class="border-bottom">⑦</td>
      <td class="border-bottom">常に就床を要し、複雑な介護を要する人</td>
      <td class="border-bottom border-left bit-padding-left">左記の全て</td>
    </tr>
    <tr>
      <td class="border-bottom">⑧</td>
      <td class="border-bottom">精神や身体に障害のある年齢65歳以上の人で、その障害の程度が上記①、②又は④に準ずる</td>
      <td class="border-bottom border-left bit-padding-left">障害の程度が①、②又は④に準ずるものとして市町村長等の認定を受けている人</td>
    </tr>
  </tbody>
</table>
`;

export const widow_content = `
<table class="employee-style separater-little">
  <thead>
    <tr>
      <th class="text-left">以下の<span style="color:red">全て</span>に該当する人はひとり親です。</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        ・合計所得金額が48万円（給与収入103万）以下の生計を一にする子を有する。
      </td>
    </tr>
    <tr>
      <td>
      ・あなたの合計所得金額が500万円（給与収入678万円）以下である。
      </td>
    </tr>
    <tr>
      <td>
      ・住民票の続柄に「未届の妻」など事実婚の記載がない。
      </td>
    </tr>
  </tbody>
</table>

<table class="employee-style separater-each">
  <thead>
    <tr>
      <th class="text-left">ひとり親に該当せず、次のいずれかに当てはまる人は寡婦です。</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        ・夫と死別し、もしくは離婚した後婚姻をしていない人、または夫の生死が明らかでない人で、扶養親族がいる人または生計を一にする子がいる人です。
        この場合の子は、年間所得が38万円以下で、他の人の控除対象配偶者や扶養親族となっていない人に限られます。
      </td>
    </tr>
    <tr>
      <td>
        ・夫と死別した後婚姻をしていない人、または夫の生死が明らかでない人で、受給者本人の所得金額が500万円以下の人です。この場合は扶養親族などの要件はありません。
      </td>
    </tr>
  </tbody>
</table>
`;

export const work_student_content = `
<table class="employee-style">
  <thead>
    <tr>
      <th class="text-left">以下の<span style="color:red">全て</span>に該当する人は勤労学生です。</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        ・特定の学校の生徒、生徒、訓練生であること<br>
        <p style="margin-left:10px;font-size:12px;">
          学校教育法に規定する中学校、高等学校、大学など<br>
          一定の要件を備えた専修学校、各種学校や職業訓練法人
        </p>
      </td>
    </tr>
    <tr>
      <td>
        ・合計所得金額が65万円(給与収入130万円)以下
      </td>
    </tr>
    <tr>
      <td>
        ・本人の勤労による所得（事業、給与、退職、雑所得）以外の所得が10万円以下
      </td>
    </tr>
  </tbody>
</table>
`;

export const branch_content = `
<p>例)渋谷店所属だが新宿店でもシフトに入る場合</p>
<p>主部署/店舗・・・渋谷店</p>
<p>従部署/店舗・・・渋谷店と新宿店</p>
<p>を選択します。</p>
`;

export const single_child_content = `
<table class="employee-style separater-little">
  <thead>
    <tr>
      <th class="text-left">以下の<span style="color:red">全て</span>に該当する人はひとり親です。</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        ・合計所得金額が48万円（給与収入103万）以下の生計を一にする子を有する。
      </td>
    </tr>
    <tr>
      <td>
      ・あなたの合計所得金額が500万円（給与収入678万円）以下である。
      </td>
    </tr>
    <tr>
      <td>
      ・住民票の続柄に「未届の妻」など事実婚の記載がない。
      </td>
    </tr>
  </tbody>
</table>
`;

export const emp_ins_get_job_kind = `
<table class="info-style separater-little">
  <thead>
    <tr>
      <th class="text-center" style="min-width: 230px;">職種</th>
      <th class="text-center">説明</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>管理的職業</td>
      <td>会社・団体等の役員及び管理職員（経営組織の課以上の長）をいいます。（会社部長、課長、支店長、工場長、営業所長）</td>
    </tr>
    <tr>
      <td>専門的・技術的職業</td>
      <td>教育の仕事、医学の知識を必要とする専門的な仕事、芸術作品の創作・演奏・上演の仕事に従事するもの、その他研究者、法務従事者、公認会計士などの専門家及び技術者をいいます。（機械技師、建築家、教員、看護師、デザイナー、俳優、物理学者、記者、カメラマン、無線通信員）</td>
    </tr>
    <tr>
      <td>事務的職業</td>
      <td>現金の出納、帳簿、文書、記録などの作成 ･ 管理事務機械の操作調査などの経営管理の補助的な業務に従事するものをいいます。（経理事務員、現金出納事務員、文書係事務員、人事係事務員、受付事務員、タイピスト、キーパンチャー、現場事務員、電話交換手）</td>
    </tr>
    <tr>
      <td>販売の職業</td>
      <td>商品 ･ 不動産 ･ 有価証券などの売買、売買の仲介 ･ 代理、勧誘などの業務に従事するものをいいます。（販売店員、販売外交員、サービス外交員、保険外交員）</td>
    </tr>
    <tr>
      <td>サービスの職業</td>
      <td>家事に従事するもの、個人の身のまわり用務、娯楽などの接客サービスに従事するもの、料理、洗濯、職業スポーツなどその他のサービスの業務に従事するものをいいます。（理容師、給仕人、旅館番頭、ドアマン、接客員、料理人、バーテンダー、洗濯工、ガイド）</td>
    </tr>
    <tr>
      <td>保安の職業</td>
      <td>個人・財産の保護、秩序の維持などに従事するものをいいます。（守衛、監視人、警備員、消防員）</td>
    </tr>
    <tr>
      <td>農林漁業の職業</td>
      <td>農業、林業及び漁業に従事するものをいいます。（果実栽培労務者、園芸労務者、伐木人、漁師、養魚作業者）</td>
    </tr>
    <tr>
      <td>生産工程の職業</td>
      <td>各種作品製造の工程作業に従事するもの、技術補助工など短期間に習得でき、かつほとんど判断を要しない簡単な作業に従事するものも含めます。（機械工、溶接工、修理工、組立工、製鉄工、現図工、塗装工、紡績工、木工、印刷工、成型工、製菓工、科学工）</td>
    </tr>
    <tr>
      <td>輸送・機械運転の職業</td>
      <td>自動車 ･ 電車 ･ 船舶 ･ 飛行機の運転、通信機の操作、電話交換、車掌その他の運輸の作業に従事するものをいいます。（バス運転手、トラック運転手、電車運転手、電車車掌、観光バス車掌）</td>
    </tr>
    <tr>
      <td>建設・採掘の職業</td>
      <td>建設・電気工事作業、土砂掘削、鉱物採掘業務等に従事するものをいいます。</td>
    </tr>
    <tr>
      <td>運搬・清掃・包装等の職業</td>
      <td>貨物等の運搬、建物等の清掃、品物の包装等の業務に従事するものをいいます。</td>
    </tr>
  </tbody>
</table>
`;

export const social_ins_dependant_document = `
<p>・「所得税法の規定による控除対象配偶者・扶養親族」で事業主の証明がある方は、原則として、以下の添付書類は不要です。</p>
<p>(※60日以上遡及して届出を提出する場合等、個別のケースによっては、提出をお願いする場合があります。)</p>

<table class="info-style separater-little social_ins_dependant_document">
  <tbody>
    <tr>
      <td>ア. 退職により収入要件を満たす場合</td>
      <td>退職証明書または雇用保険被保険者離職票のコピー</td>
    </tr>
    <tr>
      <td>イ. 失業給付受給中、または受給終了で収入要件を満たす場合</td>
      <td>雇用保険受給資格者証のコピー</td>
    </tr>
    <tr>
      <td>ウ. 年金受給中の場合</td>
      <td>現在の年金受取額がわかる年金額の改定通知書等のコピー</td>
    </tr>
    <tr>
      <td>エ. 自営(農業等を含む)による収入、不動産収入等がある場合</td>
      <td>直近の確定申告書のコピー</td>
    </tr>
    <tr>
      <td>オ. 上記イウエ以外に他の収入がある場合</td>
      <td>イウエに応じた書類及び課税(非課税)証明書</td>
    </tr>
    <tr>
      <td>カ. 上記ア〜オ以外</td>
      <td>課税(非課税)証明書</td>
    </tr>
  </tbody>
</table>

<p class="separater-little">・障害年金、遺族年金、傷病手当金、失業給付等非課税対象の収入がある場合は、受取金額の確認ができる通知書等のコピーが別途必要です。</p>
<p>・被扶養者の非該当・変更の場合は、被保険者証の添付が必要です。添付できない場合は『被保険者証回収不能届』を添付してください。</p>
<p>・被保険者が被扶養者を扶養する確認書類を事業主に提出できない場合は、『扶養に関する申立書』欄に不要の状況を記入しご提出ください。</p>
<p>・以下の続柄に該当する場合は添付してください。</p>

<table class="info-style separater-little social_ins_lost_date">
  <tbody>
    <tr>
      <td>続柄が被保険者の曽祖父母・祖父母・父母・兄弟姉妹・配偶者・子・孫以外の場合</td>
      <td>被保険者世帯全員の住民票の写し(証明できない場合は民生委員の同居証明等</td>
    </tr>
    <tr>
      <td>配偶者と内縁関係にある場合</td>
      <td>内縁関係にある両人の戸籍謄(抄)本、被保険者世帯全員の住民票の写し等</td>
    </tr>
  </tbody>
</table>

<p class="separater-little">※提出日からさかのぼって90日以内に発行されたものであること</p>

<style>
.social_ins_dependant_document td {
  font-size: 10pt!important;
}
</style>
`;

export const social_ins_lost_date = `
<p>喪失年月日は喪失事由によって異なります。</p>

<table class="info-style separater-little social_ins_lost_document">
  <tbody>
    <tr>
      <td>退職等による資格喪失</td>
      <td>退職日の翌日<br>転勤の当日<br>雇用契約変更の当日</td>
    </tr>
    <tr>
      <td>死亡による資格喪失</td>
      <td>死亡日の翌日</td>
    </tr>
    <tr>
      <td>75歳到達による健康保険の資格喪失</td>
      <td>誕生日の当日</td>
    </tr>
    <tr>
      <td>障害認定による健康保険の資格喪失</td>
      <td>認定日の当日</td>
    </tr>
    <tr>
      <td>社会保険協定による資格喪失</td>
      <td>社会保険協定発効の当日<br>相手国法令の適用となった日の翌日</td>
    </tr>
  </tbody>
</table>

<style>
.social_ins_lost_date td {
  font-size: 10pt!important;
}
</style>
`;

export const social_ins_lost_reason = `
<table class="info-style separater-little social_ins_lost_reason">
  <tbody>
    <tr>
      <td>退職等</td>
      <td>退職した場合<br>雇用契約の変更等により被保険者の適用対象外となった場合<br>退職後に継続して再雇用した場合</td>
    </tr>
    <tr>
      <td>死亡</td>
      <td>死亡した場合</td>
    </tr>
    <tr>
      <td>75歳到達</td>
      <td>75歳に到達したことで後期高齢者医療に該当し、健康保険の被保険者資格を喪失する場合</td>
    </tr>
    <tr>
      <td>障害認定</td>
      <td>65歳以上75歳未満の方で、障害認定により後期高齢者医療に該当し、健康保険の被保険者資格を喪失する場合</td>
    </tr>
    <tr>
      <td>社会保障協定</td>
      <td>社会保障協定により、相手国法令の適用を受け、被保険者資格を喪失する場合</td>
    </tr>
  </tbody>
</table>

<style>
.social_ins_lost_reason td {
  font-size: 10pt!important;
}
</style>
`;
