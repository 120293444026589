import {
  disability_content,
  widow_content,
  work_student_content,
  branch_content,
  single_child_content,
  emp_ins_get_job_kind,
  social_ins_dependant_document,
  social_ins_lost_date,
  social_ins_lost_reason,
} from "@/library/info_contents";

export default {
  //employee_each, spouce, dependant
  disability: {
    title: "障害者・特別障害者",
    msg: "次のいずれかに該当する人は障害者・特別障害者です",
    maxwidth: "900px",
    content: disability_content,
  },
  widow: { title: "ひとり親・寡婦", maxwidth: "900px", content: widow_content },
  work_student: { title: "勤労学生", maxwidth: "900px", content: work_student_content },
  week_prescribed_day: {
    title: "週所定労働日数",
    msg: (h) => [
      "有給休暇の自動計算に",
      h("span", { style: { class: "font-red" } }, "必須"),
      "です。",
    ],
  },
  week_prescribed_time: {
    title: "週所定労働時間",
    msg: (h) => [
      "有給休暇の自動計算に",
      h("span", { style: { class: "font-red" } }, "必須"),
      "です。",
    ],
  },
  month_prescribed_day: {
    title: "月所定労働日数",
    msg: "ARROWではシフトから自動計算されますが、この項目を入力すると入力された値が常に使用されます。",
  },
  month_prescribed_time: {
    title: "月所定労働時間",
    msg: "ARROWではシフトから自動計算されますが、この項目を入力すると入力された値が常に使用されます。",
  },
  joined_on: {
    title: "入社年月日",
    msg: (h) => [
      "有給休暇の自動計算に",
      h("span", { style: { class: "font-red" } }, "必須"),
      "です。",
    ],
  },
  retired_on: { title: "退社年月日", msg: "入力された日付から退職者として扱われます。" },
  child_holiday: {
    title: "産前産後・育児休業",
    msg: "入力期間中は、健康・介護・厚生年金保険料が自動的に0円になります。",
  },
  social_ins_got_on: {
    title: "資格取得年月日(社会保険)",
    msg: "入力された日付から自動で適用されます。",
  },
  emp_ins_got_on: {
    title: "資格取得年月日(雇用保険)",
    msg: "入力された日付から自動で適用されます。",
  },
  comp_ins_got_on: {
    title: "資格取得年月日(労災保険)",
    msg: "入力された日付から自動で適用されます。",
  },
  social_ins_id: {
    title: "被保険者整理番号(社会保険)",
    msg: "社会保険の被保険者に割り振られている番号です。<br>健康保険証の「番号」の箇所に書かれている数字が被保険者番号です。",
  },
  single_child: { title: "ひとり親", content: single_child_content },
  earning: {
    title: "収入と所得",
    msg: (h) => [
      "収入 : 社員やパートアルバイトに支給される給与など",
      "所得 : 収入から「会社員の必要経費とみなされるもの」を引いたもの",
      "",
      "所得は決められた式によって計算されます。",
      "詳しくは",
      h("a", { target: "__blank", href: "https://help.arrow-payment.com/law/earning/" }, "こちら"),
      "をご覧ください。",
    ],
  },
  dependant_num: {
    title: "扶養人数",
    msg: (h) => [
      "税制上の扶養人数と実際の扶養人数は異なることがあります。",
      "詳しくは",
      h(
        "a",
        { target: "__blank", href: "https://help.arrow-payment.com/law/dependantnum/" },
        "こちら",
      ),
      "をご覧ください。",
    ],
  },
  employee_type: {
    title: "用途",
    msg: (h) => [
      "ARROWでの従業員の用途です。",
      "用途によって、社員情報の必須項目が変化します。",
      "※あくまで社員情報の入力項目を増減させるための機能であり、用途以外のページにも表示されます。",
      h(
        "a",
        { target: "__blank", href: "https://help.arrow-payment.com/employee/employee_each/type/" },
        "こちら",
      ),
      "をご覧ください。",
    ],
  },
  insufficient_attendance_rate: {
    title: "出勤率不足",
    msg: "有給休暇の計算期間における出勤率が80%未満のため、有給休暇が付与されません。<br>出勤率は週所定労働日数に基づいて、以下の式で計算されます。<br><br>(出勤率) = (勤務・有給休暇使用などの日数) / (計算期間の所定労働日数)<br><br>(計算期間の所定労働日数) = ((週所定労働日数) / 7(1週間の日数)) × (計算期間の日数)<br><br>※「計算期間」について、入社後初めて与えられる有給休暇の場合は入社してから半年間、それ以降の有給休暇の場合は前回の付与から1年間が計算期間となります。",
  },

  main_branch: {
    title: "主部署/店舗",
    msg: "メインで所属する店舗です。<br>勤怠や給与などはこの店舗への所属として計算されます。",
    content: branch_content,
  },
  sub_branch: {
    title: "主部署/店舗",
    msg: "サブで所属する店舗です。<br>別店舗のシフトに入る場合に使用します。",
    content: branch_content,
  },

  //config
  close_day: {
    title: "締め日",
    msg: "勤怠を締める日です。<br>その日までが給与の計算期間となります。",
  },
  pay_month: {
    title: "支払月",
    msg: "給与の振り込み月です。<br>当月の場合は勤怠の締め日と同月に振り込まれます。<br>翌月の場合は勤怠の締め日の翌月に振り込まれます。",
  },
  pay_day: {
    title: "支払日",
    msg: "給与の振り込み日です。<br>金融機関が休みの場合は、直前の営業日が振り込み日となります。",
  },
  levy_month: {
    title: "社会保険料徴収時期",
    msg: "翌月徴収：当月分の社会保険料を翌月支給の給与から控除します。<br>当月徴収：当月分の社会保険料を当月支給の給与から控除します。",
  },
  outhol: {
    title: "法定外休日",
    msg: "法律で定められていない休日です。<br>労働基準法では週に1日以上または4週に4日以上の休日を与えなければいけないと定められています。",
  },
  lawhol: {
    title: "法定休日",
    msg: "法律で週1日以上与えることが義務づけられている休日であり、法定休日での出勤には35%以上の割増賃金の支給が義務付けられています。",
  },
  lawover: {
    title: "法定外残業",
    msg: "所定労働時間が７時間、勤務時間が９時間の場合、８時間を超えた１時間分が法定外残業時間となります。法定外残業には25%以上の割増賃金の支給が義務付けられています。",
    img: "info/lawover_info.svg",
  },
  preover: {
    title: "法定内残業",
    msg: "所定労働時間が７時間、勤務時間が９時間の場合、７時間を超えた１時間分が法定内残業時間となります。",
    img: "info/preover_info.svg",
  },
  mid: {
    title: "深夜",
    msg: "22:00~翌5:00までの深夜勤務には25%以上の割増賃金の支給が義務付けられています。",
  },
  punch_round: {
    title: "勤怠丸め",
    msg: "勤務時間：8:57〜17:08　シフト時間：09:00〜17:00<br><br>①：10分単位で切り上げの場合、勤務時間は08:50〜17:10になります。<br>②：10分単位で切り捨ての場合、勤務時間は09:00〜17:00になります。<br>③：10分単位で四捨五入の場合、勤務時間は09:00〜17:10になります。",
    img: "info/round_info.svg",
  },
  break_round: {
    title: "休憩丸め",
    msg: "休憩時間打刻：11:57〜13:08<br><br>①：10分単位で切り上げの場合、休憩時間は11:50〜13:10になります。<br>②：10分単位で切り捨ての場合、休憩時間は12:00〜13:00になります。<br>③：10分単位で四捨五入の場合、休憩時間は12:00〜13:10になります。",
    img: "info/round_break_info.png",
  },
  order_overtime: {
    title: "残業申請",
    msg: "残業申請を有効にすると、マイページで残業申請を行うことができるようになります。<br>また、シフトの時間に含まれない勤怠が全て無効になります。<br>マイページから残業を申請し、管理者が承認することで、その分の勤怠が有効となります。<br>勤怠丸めの設定をしている場合でも、無効となった勤務には勤怠丸めは行われず、シフトに合わせて切り捨てられます。",
  },
  order_holiday: {
    title: "休暇申請",
    msg: "休暇申請を有効にすると、マイページで休暇申請を行うことができるようになります。<br>マイページから休暇(有給・特別休暇など)を申請し、管理者が承認することで、その日の勤務形態が変更されます。",
  },
  order_change: {
    title: "変更申請",
    msg: "変更申請を有効にすると、マイページで社員情報の変更申請を行うことができるようになります。<br>マイページから社員情報変更を申請し、管理者が承認することで、社員情報を変更することができます。<br>ただし、会社設定項目(給与・税・社会保険など)に関しては申請できません。",
  },
  order_punch: {
    title: "打刻修正申請",
    msg: "打刻修正申請を有効にすると、マイページで打刻修正申請を行うことができるようになります。<br>打刻を忘れた場合や、誤った打刻をしてしまった場合に、マイページから打刻修正を申請し、管理者が承認することで、打刻の追加・変更・削除ができます。",
  },
  order_join: {
    title: "入社申請",
    msg: "入社申請の承認権限を、会社管理者のみに与えるか、店舗管理者にも与えるかを選択できます。",
  },
  mail_shift_alert: {
    title: "遅刻アラート",
    msg: "遅刻アラートを有効にすると、指定した遅刻時間を過ぎた時にメールが送信されます。<br>メールの送信は5分間隔のため、指定した遅刻時間丁度に送信されるわけではありません。",
  },
  mail_need: {
    title: "シフト募集",
    msg: "シフト募集を行った際にシフト提出を促すメールを送信する通知先を選択できます。",
  },
  shift_nickname: {
    title: "従業員名変更(ニックネーム)の使用",
    msg: "「使用する」を選択すると、従業員の詳細設定に「シフトページの表示名」という入力欄が追加されます。<br>この入力欄が空欄であれば本名が表示され、入力されている場合はそのニックネームが表示されます。<br>ニックネームの表示はマイページのみで適用され、管理者側では本名が表示されます。",
  },
  shift_summery: {
    title: "シフトサマリー",
    msg: "シフトサマリーの計算期間を選択できます。<br>「月ごと」を選択した場合は1日から月末までが計算期間とし、「給与計算期間」を選択した場合は締め日の翌日から翌月の締め日までが計算期間となります。",
  },
  shift_employee: {
    title: "マイページでの表示",
    msg: "マイページでシフトページを表示した際、他の人のシフトを表示するかを設定することができます。<br>「全員」を選択すると、自分が所属している店舗のシフトは全て閲覧できます。<br>「自分のシフトが存在する日の全員」を選択すると、自分がシフトに入っている日のみ全員のシフトが閲覧できます。<br>「自分のみ」を選択すると、自分のシフトのみが閲覧できます。",
  },
  punch_gps: {
    title: "GPS打刻",
    msg: "GPS打刻を有効にすると、スマートフォンやタブレットでマイページにログインした際にGPS打刻のボタンが表示されます。",
  },
  punch_mypc: {
    title: "MyPC打刻",
    msg: "MyPC打刻を有効にすると、PCでマイページにログインした際にMyPC打刻のボタンが表示されます。<br>また、マイページの社員詳細ページにMyPC打刻のPC登録ボタンが表示されます。<br>PC登録には、管理者権限を持つアカウントでの認証が必要です。<br>また、キャッシュなどを削除するとPC登録が解除される可能性があります。",
  },
  punch_alert: {
    title: "シフト外出勤アラート",
    msg: "「あり」を選択すると、シフトがないのに打刻をした際に「シフト外出勤をしてよろしいですか？」という確認が表示されます。",
  },
  attendance_add_paidhol: {
    title: "有給休暇・特別休暇時間を合計勤務時間に加算",
    msg: "「加算する」を選択すると、勤怠ページやCSVに表示される合計勤務時間に有給休暇・特別休暇時間が加算されます。<br>表示上のものであり、勤怠計算に影響があるものではありません。",
  },
  attendance_employee_chart: {
    title: "社員へのチャート表示",
    msg: "マイページで勤怠詳細ページを表示した際に、残業などの細かいチャートを表示するボタンを使用できるかどうかを選択できます。",
  },
  attendance_deem_over: {
    title: "固定残業",
    msg: "固定残業の計算に法定内残業を含めるかどうかを選択できます。",
  },
  attendance_noshift_error: {
    title: "シフトなしエラー",
    msg: "打刻があるのにシフトがない場合に勤怠ページに表示されるエラーを表示するかを選択できます。",
  },
  attendance_calc_paidhol: {
    title: "有給休暇日数自動計算",
    msg: "勤怠ロック時に有給休暇の付与や40日を超えた分の削除を自動で行うかどうかを選択できます。<br>この機能を「使用しない」にした場合でも、以下の機能は無効になりません。<br>・有給休暇の使用に関する機能<br>・2年を超えた分の有給休暇の自動削除",
  },
  payment_zero: {
    title: "0円項目表示",
    msg: "従業員がみる給与明細に、0円の項目を表示するかどうかを選択できます。<br>管理者ログインで表示する給与明細では0円項目は常に表示されます(編集によって0円でなくなる可能性があるため)。",
  },
  payment_remain_paidhol: {
    title: "有給休暇残日数表示",
    msg: "給与明細に有給休暇の残日数を記載するかどうかを選択できます。",
  },
  employee_banknum: {
    title: "口座数",
    msg: "従業員が登録できる口座数を選択できます。<br>2口座の場合、1つ目の口座に振り込む上限額を設定できるようになり、その上限額を超えた分が2つ目の口座へ振り込まれるようになります。",
  },
  employee_branchly_hourly_wage: {
    title: "店舗別時給",
    msg: "複数の店舗に勤務していて、店舗ごとに時給が異なる社員がいる場合に、「あり」を選択してください。<br>社員詳細ページ->会社設定項目タブ内の基本給で時給を選択した際に、例外時給を追加できるようになります。",
  },
  employee_branchly_carfare: {
    title: "店舗別通勤手当",
    msg: "複数の店舗に勤務していて、店舗ごとに通勤手当(日額)が異なる社員がいる場合に、「あり」を選択してください。<br>社員詳細ページ->会社設定項目タブ内の通勤手当で日額を選択した際に、例外通勤手当を追加できるようになります。",
  },
  employee_noincometax: {
    title: "源泉所得税の計算の有無",
    msg: "非居住者などの場合で租税条約により免税となる従業員がいる場合に「使用する」を選択してください。<br>社員詳細ページ->会社設定項目タブ内に源泉所得税の計算の有無を選択する項目が表示されます。",
  },
  employee_double_biz: {
    title: "二以上事業所勤務者の有無",
    msg: "二以上事業所勤務者がいる場合に「使用する」を選択してください。<br>社員詳細ページ->会社設定項目タブの標準報酬月額の選択欄右に「二以上事業所勤務者」という項目が表示されます。",
  },
  branch_bank: {
    title: "振込単位",
    msg: "従業員への振り込み用の銀行口座が店舗ごとに存在する場合は「店舗単位」を選択してください。<br>設定ページの「部署/店舗」タブにて、店舗ごとの口座設定が可能になります。",
  },

  //allowance
  prescribed_day: {
    title: "所定労働日数",
    msg: "シフトが入っている日数です",
    link: "https://help.arrow-payment.com/law/month_prescribed_day",
  },
  all_day: { title: "合計勤務日数", msg: "出勤した日数です" },
  reg_day: { title: "通常勤務日数", msg: "形態が通常の日に出勤した日数です" },
  lawhol_day: {
    title: "法定休日勤務日数",
    msg: "形態が法定休日の日に出勤した日数です",
    link: "https://help.arrow-payment.com/law/lawhol",
  },
  outhol_day: {
    title: "法定外休日勤務日数",
    msg: "形態が法定外休日の日に出勤した日数です",
    link: "https://help.arrow-payment.com/law/outhol",
  },
  mid_day: {
    title: "内深夜勤務日数",
    msg: "22時〜翌5時に勤務した日数です　他の勤務日数と重複していることがあります",
    link: "https://help.arrow-payment.com/law/mid",
  },
  early_day: {
    title: "早退日数",
    msg: "シフトの終了時間よりも早く退勤した日数です",
    link: "https://help.arrow-payment.com/law/early",
  },
  late_day: {
    title: "遅刻日数",
    msg: "シフトの開始時刻よりも遅く出勤した日数です",
    link: "https://help.arrow-payment.com/law/late",
  },
  abs_day: {
    title: "欠勤日数",
    msg: "形態が欠勤の日数です",
    link: "https://help.arrow-payment.com/law/abs",
  },
  paid_holiday: {
    title: "有給休暇日数",
    msg: "形態が有給の日数です",
    link: "https://help.arrow-payment.com/law/paid_holiday",
  },
  paid_holiday_remain: { title: "有給残日数", msg: "現在残っている有給日数です" },
  special_holiday: {
    title: "特別休暇日数",
    msg: "形態が特別休暇の日数です",
    link: "https://help.arrow-payment.com/law/special_holiday",
  },
  trans_holiday: {
    title: "振替休日日数",
    msg: "形態が振替休日の日数です",
    link: "https://help.arrow-payment.com/law/trans_holiday",
  },
  comp_holiday: {
    title: "代休日数",
    msg: "形態が代休の日数です",
    link: "https://help.arrow-payment.com/law/comp_holiday",
  },

  prescribed_time: {
    title: "所定労働時間",
    msg: "シフトの時間です",
    link: "https://help.arrow-payment.com/law/month_prescribed_day",
  },
  all_time: { title: "合計勤務時間", msg: "勤務した時間です" },
  reg_time: { title: "通常勤務時間", msg: "残業・休日に該当しない労働時間です" },
  lawhol_time: {
    title: "法定休日勤務時間",
    msg: "形態が法定休日の日に勤務した時間です",
    link: "https://help.arrow-payment.com/law/lawhol",
  },
  outhol_time: {
    title: "法定外休日勤務時間",
    msg: "形態が法定外休日の日に出勤した時間です",
    link: "https://help.arrow-payment.com/law/outhol",
  },
  mid_time: {
    title: "内深夜勤務時間",
    msg: "22時〜翌5時に勤務した時間です　他の勤務時間と重複していることがあります",
    link: "https://help.arrow-payment.com/law/mid",
  },
  early_time: {
    title: "早退時間",
    msg: "シフトの終了時間よりも早く退勤した時間です",
    link: "https://help.arrow-payment.com/law/early",
  },
  late_time: {
    title: "遅刻日数",
    msg: "シフトの開始時刻よりも遅く出勤した時間です",
    link: "https://help.arrow-payment.com/law/late",
  },
  preover_time: {
    title: "法定内残業時間",
    msg: "その日の所定労働時間を超えているが8時間に満たない労働時間です",
    link: "https://help.arrow-payment.com/law/preover",
  },
  lawover_time: {
    title: "法定外残業時間",
    msg: "8時間を超えて働いた時間です",
    link: "https://help.arrow-payment.com/law/lawover",
  },
  lawhol_day_num: { title: "法定休日日数", msg: "形態が法定休日に設定されている日数です" },
  outhol_day_num: { title: "法定外休日日数", msg: "形態が法定外休日に設定されている日数です" },

  allowance_base_pay: {
    title: "基礎賃金の対象",
    msg: "割増賃金（残業・深夜・休日手当）を自動に設定していて、基礎時給の計算に含める項目の場合には、チェックしてください。労働と直接関係が薄く、個人的事情に基づいて支給される以下のようなものは除外します。<br>①家族手当　②通勤手当　③別居手当　④子女教育手当　⑤住宅手当　⑥臨時に支払われた賃金　⑦1カ月を超える期間ごとに支払われる賃金",
  },
  allowance_cash_pay: {
    title: "現物支給",
    msg: "賄いなどの現物支給の項目の場合には、チェックしてください。自動的に精算金のマイナスと両建てになります。",
  },
  allowance_stdincome: {
    title: "報酬月額",
    msg: "算定基礎届の「報酬」に含める場合には、チェックしてください。",
  },
  allowance_init: {
    title: "初期値",
    msg: "手動：元々の値は0となります。<br>前月と同じ：前月の給与がロックされている場合は前月が使用されます。<br>単価×回数：入力された金額と選択された値を掛け合わせた値が使用されます。<br>固定値：常に入力された値が使用されます。<br>基礎単価×回数：基礎単価と選択された値を掛け合わせた値が使用されます。<br><br>どれを選択されても、給与ページで後から手動変更することが可能です。",
  },
  allowance_mutualaid: {
    title: "小規模企業共済掛金等",
    msg: "以下のいずれかに当てはまる場合に選択してください。<br>また、課税上の控除対象にもチェックをつけてください。<br><br>・独立行政法人中小企業基盤整備機構の共済契約の掛金<br>・確定拠出年金法に規定する企業型年金加入者掛金<br>・確定拠出年金法に規定する個人型年金加入者掛金<br>・心身障害者扶養共済制度に関する契約の掛金",
  },

  //branch
  work_ins_kind: { title: "事業の種類の分類", msg: "労災保険用の事業の種類の分類です。" },
  work_ins_type: { title: "事業の種類", msg: "労災保険用の事業の種類です。" },
  special_biz: {
    title: "特例措置対象事業所",
    msg: "次の条件に該当する場合には、週の法定労働時間を44時間にすることができます。<br>①常時使用する労働者が10人未満の事業場<br>②適用業種<br>(ⅰ)商業（卸売業、小売業、理美容業、倉庫業、駐車場業、不動産管理業、出版業（印刷部門を除く）、その他の商業）。<br>(ⅱ)映画・演劇業（映画の映写（映画の製作の事業を除く）、演劇、その他興業の事業）。<br>(ⅲ)保健衛生業（病院、診療所、保育園、老人ホームなどの社会福祉施設、浴場業（個室付き浴場業を除く）、その他の保健衛生業）。<br>(ⅳ)接客娯楽業（旅館業、飲食店、ゴルフ場、娯楽場、公園・遊園地、その他の接客娯楽業）。",
  },

  //nencho_loan
  past_loan_balance1: { img: "info/loan/past/loan_balance1.png" },
  past_loan_balance2: { img: "info/loan/past/loan_balance2.png" },
  past_loan_new_area: { img: "info/loan/past/loan_new_area.png" },
  past_loan_new_is: { img: "info/loan/past/loan_new_is.png" },
  past_loan_new_lived_on: { img: "info/loan/past/loan_new_lived_on.png" },
  past_loan_new_money: { img: "info/loan/past/loan_new_money.png" },
  past_loan_new_residential_area: { img: "info/loan/past/loan_new_residential_area.png" },
  past_loan_new_special: { img: "info/loan/past/loan_new_special.png" },
  past_loan_new_type: { img: "info/loan/past/loan_new_type.png" },
  past_loan_reform_is: { img: "info/loan/past/loan_reform_is.png" },
  past_loan_reform_lived_on: { img: "info/loan/past/loan_reform_lived_on.png" },
  past_loan_reform_money: { img: "info/loan/past/loan_reform_money.png" },
  past_loan_reform_residential_money: { img: "info/loan/past/loan_reform_residential_money.png" },
  past_loan_reform_special: { img: "info/loan/past/loan_reform_special.png" },

  after2020_loan_balance1: { img: "info/loan/after2020/loan_balance1.png" },
  after2020_loan_balance2: { img: "info/loan/after2020/loan_balance2.png" },
  after2020_loan_reform_special: { img: "info/loan/after2020/loan_reform_special.png" },
  after2020_loan_new_house_ratio: { img: "info/loan/after2020/loan_new_house_ratio.png" },
  after2020_loan_new_is: { img: "info/loan/after2020/loan_new_is.png" },
  after2020_loan_new_land_ratio: { img: "info/loan/after2020/loan_new_land_ratio.png" },
  after2020_loan_new_lived_on: { img: "info/loan/after2020/loan_new_lived_on.png" },
  after2020_loan_new_money: { img: "info/loan/after2020/loan_new_money.png" },
  after2020_loan_new_residential_ratio: {
    img: "info/loan/after2020/loan_new_residential_ratio.png",
  },
  after2020_loan_new_special: { img: "info/loan/after2020/loan_new_special.png" },
  after2020_loan_new_type: { img: "info/loan/after2020/loan_new_type.png" },
  after2020_loan_reform_is: { img: "info/loan/after2020/loan_reform_is.png" },
  after2020_loan_reform_lived_on: { img: "info/loan/after2020/loan_reform_lived_on.png" },
  after2020_loan_reform_money: { img: "info/loan/after2020/loan_reform_money.png" },
  after2020_loan_reform_ratio: { img: "info/loan/after2020/loan_reform_ratio.png" },
  after2020_loan_reform_residential_ratio: {
    img: "info/loan/after2020/loan_reform_residential_ratio.png",
  },

  prev_payment_address: { img: "info/prev_payment/prev_payment_address.png" },
  prev_payment_name: { img: "info/prev_payment/prev_payment_name.png" },
  prev_payment_pay: { img: "info/prev_payment/prev_payment_pay.png" },
  prev_payment_retired_on: { img: "info/prev_payment/prev_payment_retired_on.png" },
  prev_payment_social_ins: { img: "info/prev_payment/prev_payment_social_ins.png" },
  prev_payment_tax_type: { img: "info/prev_payment/prev_payment_tax_type.png" },
  prev_payment_tax: { img: "info/prev_payment/prev_payment_tax.png" },

  emp_ins_get_kind: {
    title: "取得区分",
    msg: "新規　：雇用保険に加入したことがない、または、資格喪失後7年以上経過している<br>再取得：それ以外",
  },
  emp_ins_get_reason: {
    title: "被保険者となったことの原因",
    msg: "新規雇用(新規学卒)：新規雇用で、卒業した年の3月1日から6月30日の間に雇用した場合<br>新規雇用(その他)：それ以外の新規雇用の場合<br>日雇からの切替：日雇い労働者が雇用保険被保険者になる場合<br>出向元への復帰等(65歳以上)：65歳以上で出向元に復帰した場合<br>その他：以上に当てはまらない場合、右の列に原因を記述してください",
  },
  emp_ins_get_hiring_type: {
    title: "雇用形態",
    msg: "日雇：日雇い労働者<br>派遣：派遣労働者<br>パートタイム：短時間労働者(所定労働時間が週30時間未満)<br>有期契約労働者：契約期間に定めがあり、「派遣」「パートタイム」に該当しない場合<br>季節的雇用：季節的業務に期間を定めて雇用される人、または、季節的に入離職する人<br>船員：船員に該当する人<br>その他：フルタイムで働く常用労働者",
  },
  emp_ins_get_job_kind: { title: "職種", maxwidth: "900px", content: emp_ins_get_job_kind },
  emp_ins_get_joinway: {
    title: "就職経路",
    msg: "安定所紹介：ハローワーク経由での入社の場合<br>自己就職：自ら応募して入社した場合<br>民間紹介：人材紹介会社経由での入社の場合<br>把握していない：就職経路が分からない場合",
  },
  emp_ins_get_abroad_num: {
    title: "在留カード番号",
    img: "info/emp_ins_get/employee_abroad_num.png",
  },
  emp_ins_get_abroad_stay_date: {
    title: "在留期間",
    img: "info/emp_ins_get/employee_abroad_stay_date.png",
  },
  emp_ins_get_roma_name: {
    title: "被保険者名(ローマ字)",
    img: "info/emp_ins_get/employee_roma_name.png",
  },
  emp_ins_get_abroad_out_qualification: {
    title: "資格外活動許可の有無",
    img: "info/emp_ins_get/employee_abroad_out_qualification.png",
  },
  emp_ins_get_abroad_country: {
    title: "国籍・地域",
    img: "info/emp_ins_get/employee_abroad_country.png",
  },
  emp_ins_get_abroad_stay_qualification: {
    title: "国籍・地域",
    img: "info/emp_ins_get/employee_abroad_stay_qualification.png",
  },
  emp_ins_lost_send: { title: "離職票交付希望", msg: "e-Govでの申請の場合は使用されません" },

  social_ins_get_is_double_biz: {
    title: "二以上事業所勤務者の取得",
    msg: "二以上事業所勤務者の場合に選択してください。また、資格取得日から10日以内に被保険者が被保険者所属選択・二以上事業所勤務届を提出する必要があります。",
  },
  social_ins_get_is_short: {
    title: "短時間労働者の取得",
    msg: "短時間労働者に係る資格取得届を提出する場合に選択してください。",
  },
  social_ins_get_is_retire: {
    title: "退職後の継続再雇用者の取得",
    msg: "被保険者資格喪失届の提出も必要です。",
  },
  social_ins_get_abroad_reason: {
    title: "住民票住所を記載できない理由",
    msg: "住民票の住所が記載できない場合は理由を選択し、右の列に郵便番号や住所を入力してください。理由で「その他」を選択した場合は、理由を右のその他入力欄へ入力してください。",
  },
  social_ins_get_zip: {
    title: "郵便番号",
    msg: "マイナンバーが登録されていない、または、社員情報として住民票住所が入力されてない場合にのみ入力してください。",
  },
  social_ins_get_address: {
    title: "住所",
    msg: "マイナンバーが登録されていない、または、社員情報として住民票住所が入力されてない場合にのみ入力してください。",
  },
  social_ins_get_address_kana: {
    title: "住所(カナ)",
    msg: "マイナンバーが登録されていない、または、社員情報として住民票住所が入力されてない場合にのみ入力してください。",
  },
  social_ins_get_is_other: {
    title: "その他(備考)",
    msg: "(1) 70歳以上被用者該当届のみの提出の場合は、「70歳以上被用者該当」を選択し、「その他(備考)」へ「該当届のみ」と入力してください。<br>(2) 健康保険法第118条第1項各号のいずれかに該当する人についてはその旨を入力してください。<br>(3) 資格取得時までに引き続いて厚生年金保険の第四種被保険者であった人は、その旨として管轄していた年金事務所名を入力してください。",
  },

  company_office_code: {
    title: "事業所整理記号",
    img: "info/company/social_ins_office_code.png",
    msg: "保険料納入告知額・領収済額通知書に記載されている、(2桁の数字)-(4文字以内の英数字カタカナひらがな)の記号です。<br>ひらがなの場合はカタカナに変換して入力してください。",
  },
  company_office_num: {
    title: "事業所番号",
    img: "info/company/social_ins_office_num.png",
    msg: "保険料納入告知額・領収済額通知書に記載されている5桁の番号です。",
  },
  company_emp_ins_office_num: {
    title: "雇用保険適用事業所番号",
    img: "info/company/emp_ins_office_num.png",
    msg: "雇用保険適用事業所設置届事業主控などに記載されている、(4桁)-(6桁)-(1桁)の番号です。",
  },

  //social_ins_dependant
  social_ins_dependant_is_pres_confirm: {
    title: "事業主確認欄",
    msg: "被扶養者の収入要件を事業主が確認した場合はチェックをつけてください。",
  },

  social_ins_dependant_spouce_type: {
    title: "異動の別",
    msg: "新しく被扶養者となった場合は「該当」を、被保険者でなくなった場合は「非該当」を、内容に変更がある場合は「変更」を選択してください。",
  },
  social_ins_dependant_spouce_is_dependant: {
    title: "扶養親族",
    msg: "扶養親族でない配偶者の場合はチェックを外してください。",
  },
  social_ins_dependant_spouce_sex: {
    title: "性別(続柄)",
    msg: "内縁関係にある場合は、「夫(未届)」か「妻(未届)」のいずれかを選択してください。",
  },
  social_ins_dependant_spouce_got_on: {
    title: "被扶養者になった(でなくなった)日",
    msg: "・「該当」の場合<br>　被保険者の資格取得と同時に提出する場合は、被保険者欄の取得年月日と同じ日付を入力してください。<br>　それ以外は、被扶養者になった日を入力してください。<br><br>・「非該当」の場合<br>　死亡による場合は死亡日の翌日を入力してください。<br>　それ以外は被扶養者でなくなった日を入力してください。",
  },
  social_ins_dependant_spouce_get_reason: {
    title: "理由",
    msg: "「該当」の場合に、被保険者の資格取得と同時に提出する場合は、「配偶者の就職」を選んでください。",
  },
  social_ins_dependant_spouce_biko: {
    title: "備考",
    msg: "「変更」の場合に、変更の内容と理由を入力してください。",
  },
  social_ins_dependant_spouce_country: {
    title: "外国籍",
    msg: "配偶者が外国籍の場合のみ記入してください。",
  },
  social_ins_dependant_spouce_abroad_kana: {
    title: "外国人通称名(カナ)",
    msg: "配偶者が外国籍の場合のみ記入してください。<br>姓と名の間に半角スペースを1文字入れてください。<br>ミドルネームを設定する場合はミドルネームの前後に半角スペースを1文字入れてください。",
  },
  social_ins_dependant_spouce_abroad_name: {
    title: "外国人通称名",
    msg: "配偶者が外国籍の場合のみ記入してください。<br>姓と名の間に半角スペースを1文字入れてください。<br>ミドルネームを設定する場合はミドルネームの前後に半角スペースを1文字入れてください。",
  },
  social_ins_dependant_spouce_is_confirm: {
    title: "続柄確認",
    msg: "事業主が被保険者と被扶養者となる配偶者との続柄を確認した場合にチェックをつけることで、添付書類を省略できます。",
  },

  social_ins_dependant_dependant_type: {
    title: "異動の別",
    msg: "新しく被扶養者となった場合は「該当」を、被保険者でなくなった場合は「非該当」を、内容に変更がある場合は「変更」を選択してください。",
  },
  social_ins_dependant_dependant_got_on: {
    title: "被扶養者になった(でなくなった)日",
    msg: "・「該当」の場合<br>、被保険者の資格取得と同時に提出する場合は、被保険者欄の取得年月日と同じ日付を入力してください。<br>それ以外は、被扶養者になった日を入力してください。<br><br>・「非該当」の場合<br>死亡による場合は死亡日の翌日を入力してください。<br>それ以外は被扶養者でなくなった日を入力してください。",
  },
  social_ins_dependant_dependant_biko: {
    title: "備考",
    msg: "「変更」の場合に、変更の内容と理由を入力してください。<br>また、別居の場合、個人番号が登録されていない場合は都道府県に続く住民票住所を入力してください。<br>さらに、別居で仕送りをしている場合は、1回あたりの仕送り額を入力してください。",
  },
  social_ins_dependant_document: { title: "添付書類", content: social_ins_dependant_document },

  social_ins_lost_social_ins_lost_on: { title: "資格喪失年月日", content: social_ins_lost_date },
  social_ins_lost_employee_lost_reason: {
    title: "喪失(不該当)原因",
    content: social_ins_lost_reason,
  },
  social_ins_lost_employee_is_double_biz: {
    title: "二以上事業所勤務者の喪失",
    msg: "2カ所以上の適用事業所で勤務している被保険者が喪失する場合にチェックをつけてください。",
  },
  social_ins_lost_employee_is_retire: {
    title: "退職後の継続再雇用者の喪失",
    msg: "60歳以上の者で、退職した者が1日の空白もなく引き続き再雇用された場合にチェックをつけてください。",
  },
  social_ins_lost_employee_biko_other: {
    title: "その他(備考)",
    msg: "転勤により資格喪失する場合は、「○○年○○月○○日転勤」と入力してください。<br>厚生年金基金の加入員である被保険者であって被保険者の資格を取得した月にその資格を喪失した者は、「加入員の資格同月得喪」と入力してください。",
  },
  social_ins_lost_employee_no_card: {
    title: "保険証回収返不能数",
    msg: "回収できなかった枚数を選択し、『被保険者証回収不能届』を提出してください。",
  },
  social_ins_lost_employee_over_seventy: {
    title: "70歳以上被用者不該当",
    msg: "70歳以上の方で資格喪失理由が退職・死亡である場合にチェックをつけてください。<br>※在職中に70歳に到達された方の厚生年金保険被保険者資格喪失届は、社会保険被保険者資格喪失届ではなく、『70歳到達届』をご提出ください。",
  },

  renew_stdincome_is_over_seventy: {
    title: "70歳以上被用者算定",
    msg: "70歳以上の従業員である場合にチェックをつけてください。<br>",
  },
  renew_stdincome_over_seventy_month: {
    title: "70歳以上被用者算定(基礎算定月)",
    msg: "4月～6月の間に70歳に到達するなどで、健康保険と厚生年金保険の算定基礎月が異なる場合は、70歳になった月を算定基礎月に記入して下さい。",
  },
  renew_stdincome_is_join_midcareer: {
    title: "途中入社",
    msg: "4月～6月に月の途中で入社したことによって1ヶ月分の給料が支払われない場合にチェックをつけてください。<br>また、「その他」に入社年月日を入力してください。",
  },
  renew_stdincome_change_monthly_wage: {
    title: "月額変更予定",
    msg: "7～9月で標準報酬月額が変更となる予定の従業員の場合は算定基礎届の提出対象でないため、チェックをつけてください。<br>月額変更届を別途提出してください。",
  },
  renew_stdincome_is_yearly_average: {
    title: "年間平均",
    msg: "年間平均での算定を行う場合はチェックをつけてください。<br>年間平均が認められるには要件があり、申立書・同意書等の添付書類を提出する必要があります。",
  },
  renew_stdincome_is_double_biz: {
    title: "二以上勤務",
    msg: "従業員が2ヵ所以上の会社で働いている場合にチェックをつけてください。",
  },
  renew_stdincome_renewed_yearmonth: {
    title: "従前改定月",
    msg: "前回の改定月を入力してください。",
  },
  renew_stdincome_type: { title: "種類", msg: "その月での従業員の種類を選択してください。" },
  renew_stdincome_day: {
    title: "日数",
    msg: "報酬の基礎となった日数です。<br>月給者は暦の日数、時給者は出勤日数を入力してください。<br>月給者で欠勤した日の給料を差し引いている場合、欠勤日数を除いた日数を入力してください。",
  },
  renew_stdincome_money: { title: "通貨", msg: "各月にお金で支払われた報酬の金額です。" },
  renew_stdincome_cash: { title: "現物", msg: "定期券や食事など現物で支給されたものの金額です。" },
  renew_stdincome_retroactive: {
    title: "遡及支払額",
    msg: "本来それ以前に支払うべき報酬が支払われた場合、その月の欄に金額を入力してください。",
  },
  renew_stdincome_sum: {
    title: "合計",
    msg: "通貨と現物の合計です。<br>種類や日数により計算対象外の月の場合は表示されません。",
  },
  renew_stdincome_adjust_average: {
    title: "修正平均額",
    msg: "遡及支払がある場合に自動計算されます。<br>また、年間平均を使用する場合は、年間の平均額を修正平均額へ入力してください。",
  },
  renew_stdincome_biko: {
    title: "その他",
    msg: "途中入社の場合は入社年月日を入力してください。<br>現物支給がある場合は名称等を入力してください。",
  },

  update_stdincome_is_only_healthins: {
    title: "健康保険のみ変更",
    msg: "70歳に到達するなどで、健康保険のみ月額変更の場合にチェックをつけてください。<br>※「70歳以上被用者月額変更」とは異なり、70歳に到達した際の契約変更などによる月額変更が対象です。",
  },
  update_stdincome_up_down_reason: {
    title: "昇給・降給の理由",
    msg: "「基本給の変更」や「家族手当の支給」など、昇給や降給となった理由を入力してください。",
  },
  update_stdincome_adjust_average: {
    title: "修正平均額",
    msg: "遡及支払がある場合に自動計算されます。",
  },
  update_stdincome_biko: { title: "その他", msg: "現物支給がある場合は名称等を入力してください。" },

  attendance_filter: {
    title: "フィルタ条件",
    msg: "フィルタの条件を入力すると、その条件を満たした従業員のみが表示されます。<br>例) 「法定外残業時間」が「30時間」「以上」と指定すると、法定外残業時間が30時間以上の従業員のみが表示されます。",
  },
  notification_way: {
    title: "通知方法",
    msg: "アプリ通知 : ARROWのマイページアプリがインストールされている場合は、アプリ通知が発行されます。また、ログイン後に通知を確認することができます。<br>メール通知 : メールにて通知内容が送信されます。<br><br>※アプリの通知が許可されていな場合など、通知が届かない場合があります。<br>※メールアドレスが登録されていない場合はメールは送信されません。",
  },
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},
  // : {title: '', msg: ''},

  // 被扶養者の認定を受けるためには、<br><ul><li>被扶養者の戸籍謄本または戸籍抄本（被保険者との続柄がわかるもの）</li><li>住民票の写し(コピー不可・個人番号の記載のないもの)</li></ul>のいずれかが必要です。<br>※住民票の写しの添付が認められるのは、被保険者が世帯主で、扶養認定を受ける人と同居している場合に限リます。<br>※いずれも提出日から90日以内に発行されたものである必要があります。。<br><br>ただし、<ul><li>被保険者と扶養認定を受ける人のマイナンバー</li><li>扶養認定を受ける人の続柄を事業主が確認し、相違ないことを確認した旨</li></ul>があれば添付を省略できます。<br>添付を省略し、事業主による確認を行った場合にチェックをつけてください。
};
